
type Props = {
  size?: number;
  color?: string;
};

export default function Cegid({ size = 40, color }: Props) {
  const st0 = {
    fill: color ?? "#0046FE",
  };

  const st1 = {
    fill: "inherit",
  };


  return (
    <svg
      className="icon"
      version="1.1"
      id="Calque_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      style={st0}
      viewBox="0 0 100 100"
      width={size + "px"}
    >
      <g id="Group-10" transform="translate(12.000000, 23.000000)">
        <path
          id="Fill-1"
          style={st1}
          d="M42,32.5c-3.2,0-5.5-2.5-5.5-6.2s2.3-6,5.5-6c3.2,0,5.5,2.5,5.5,6S45.2,32.5,42,32.5L42,32.5z
        M47.3,18h-0.8l-0.2-0.2c-1.5-1.8-3.7-2.8-5.8-2.7c-5.3,0-9.5,5-9.5,11.2s4.3,11.3,9.8,11.3c3.2,0,4.7-1.3,5.5-2.5l0.2-0.2h0.8v4.3
       c0,2-1.5,3.5-3.5,3.5h-7.2v5.5h7.8c4.5,0,8.2-3.7,8.2-8.3V15.8h-5.3V18z"
        />
        <path
          id="Fill-3"
          style={st1}
          d="M12.5,24.7v-0.3c0.5-2.5,2.7-4.3,5.2-4.2l0,0c2.5-0.2,4.7,1.7,5,4.2v0.3
       C22.7,24.7,12.5,24.7,12.5,24.7z M17.7,15.7C11.5,15.7,7,20.5,7,27s4.5,11.2,10.8,11.2l0,0c4.7,0,8.2-2.3,9.5-6.3l-4.8-1.2
       c-0.8,1.7-2.5,2.5-4.8,2.5c-3,0.2-5.2-1.8-5.2-4.8v-0.2h15.7v-1.3C28.2,20.2,23.7,15.7,17.7,15.7L17.7,15.7z"
        />
        <g id="Clip-6"></g>
        <polygon
          id="Fill-5" style={st1} points="57.3,37.5 62.8,37.5 62.8,16.7 57.3,16.7 	" />
        <path
          id="Fill-7"
          style={st1}
          d="M60.3,6.7c-1.7,0-3.2,1.3-3.2,3.2s1.3,3.3,3.2,3.3c0,0,0,0,0.2,0c1.7,0,3.2-1.5,3.2-3.3
       C63.3,8.2,62,6.7,60.3,6.7"
        />
        <path
          id="Fill-8"
          style={st1}
          d="M77.3,33c-3.2,0-5.5-2.5-5.5-6s2.3-6,5.5-6l0,0c3.2,0,5.5,2.7,5.5,6S80.5,33,77.3,33L77.3,33z
        M82.7,6.8v11.5h-0.8h-0.2c-1.5-1.7-3.7-2.7-6-2.7c-5.3,0-9.5,4.8-9.5,11.2s4.3,11.3,9.8,11.3c2.5,0,4.3-0.8,5.5-2.5l0.2-0.2h0.8v2
       H88V6.8H82.7z"
        />
        <path
          id="Fill-9"
          style={st1}
          d="M-0.2,33.2c-3.7,0-6.2-2-6.2-6.2s2.7-6.3,6-6.3c1.3,0,2.7,0.3,4,1l1.8-4.5c-2-1-4.2-1.5-6.3-1.5
       C-7.3,15.7-12,20.5-12,27c0,6.3,4.5,11,10.7,11c2.5,0,4.8-0.7,6.8-2.2l-1.8-4C2.5,32.7,1.2,33.2-0.2,33.2"
        />
      </g>
    </svg>
  );
}
